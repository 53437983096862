<template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex mb-8"
      flat
      tile
    >
      <v-card-title class="yellow darken-1">
          <v-container class="d-flex justify-space-between px-0">
                <h3 class="black--text smm-title">Подключайся к нашим соцсетям</h3>
                <div>
                    <v-btn
                      v-for="icon in icons"
                      :key="icon.icon"
                      class="mx-4"
                      icon
                      :href="icon.link"
                    >
                    <v-icon color="black" size="24px">
                        {{ icon.icon }}
                    </v-icon>
                    </v-btn>
                </div>
                
          </v-container>
      </v-card-title>
      <v-card-text class="py-2 white--text text-center">
        <v-container>
          <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-btn link text small class="pa-0" href="/#coaches">Тренеры</v-btn><br>
            <v-btn link text small class="pa-0" href="/#locations">Пляжные центры</v-btn><br>
            <v-btn link text small class="pa-0" href="/#schedule">Расписание</v-btn><br>
            <v-btn link text small class="pa-0" href="/#prices">Цены</v-btn><br>
          </v-col>
          <v-col cols="12" md="6" class="company-info" itemscope itemtype="https://schema.org/SportsOrganization">
            <v-img itemprop="image" width="54" src="@/assets/logo.png" class="d-block mx-auto"></v-img>
            {{ new Date().getFullYear() }} — <span itemprop="brand"><strong itemprop="name">Школа волейбола Vball</strong></span>
            <p itemprop="slogan" class="mb-0">Мы учим играть с нуля и сразу правильно</p>
            <p itemprop="address" class="mb-0">Москва, Проспект Маршала Жукова, 4, стр. 2</p>
            <div itemprop="contactPoint">
              <p itemprop="email" class="mb-0">info@vball.moscow</p>
              <p itemprop="telephone" class="mb-0">+7 (916) 375 05 17</p>
              <p itemprop="name" class="mb-0">Ксения Дячук</p>
            </div>
            <p itemprop="url"><v-btn link text small class="pa-0" href="https://vball.moscow">vball.moscow</v-btn></p>
            
          </v-col>
        </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
//import SendForm from "@/components/MainPage/SendForm"
  export default {
    data: () => ({
      icons: [
        {
          icon:'mdi-instagram',
          link:'https://www.instagram.com/vball_moscow/',
        },
        {
          icon:'mdi-telegram',
          link:'https://t.me/vballmsk',
        },
        {
          icon:'mdi-whatsapp',
          link:'https://wa.me/79163750517',
        },
        {
          icon:'mdi-vk',
          link:'https://vk.com/im?sel=-164644495',
        }
      ],
    }),
    /*components:{
      SendForm
    }*/
  }
</script>
<style scoped>
.smm-title{
  word-break: break-word;
}
</style>