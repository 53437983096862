<template>
    <div>
        <Main/>
        <v-card v-intersect="onIntersect" class="pa-0 ma-0" elevation="0">
        <Intro class="mt-6"/>
        <Benefits class="mt-6"/>
        <Coaches class="mt-6"/>
        <Locations class="mt-6"/>
        <Schedule class="mt-6" id="schedule" ref="schedule"/>
        <Prices class="mt-6"/>
        </v-card>
        <FormModal />
        <v-fab-transition>
          <v-btn v-show="show" color="yellow lighten-2" class="black--text cta-button" dark fixed bottom right rounded elevation="16" large @click="toggleFormModal" :disabled="formModal">
            Записаться
          </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
import Main from '@/components/MainPage/Main';
import Intro from '@/components/MainPage/Intro';
import Benefits from '@/components/MainPage/Benefits';
import Coaches from '@/components/MainPage/Coaches';
import Locations from '@/components/MainPage/Locations';
import Schedule from '@/components/MainPage/Schedule';
import Prices from '@/components/MainPage/Prices';
import FormModal from '@/components/MainPage/FormModal';
import {mapActions, mapGetters} from 'vuex'
export default {
  title: 'VBall - школа пляжного волейбола ',
  name: 'Home',
  components: {
    Main, Intro, Benefits, Coaches, Locations, Schedule, Prices, FormModal
  },
  computed: mapGetters(['formModal']),
  methods:{
    ...mapActions(['toggleFormModal']),
    onIntersect (entries) {
        this.show = entries[0].isIntersecting
      },
  },
  data: () => ({
    show: true,
    loading: false
  }),
};
</script>
<style scoped>
.cta-button{
  z-index: 999999;
}
</style>