import Vue from 'vue'
import Vuex from 'vuex'
import app from'./modules/app'
import users from'./modules/users'
import adminLogin from'./modules/adminLogin'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users, app, adminLogin
  }
})