export default{
    actions : {
        toggleFormModal({commit}){
            commit("toggleFormModal")
        },
        enableHeader({commit}, enable){
            commit("enableHeader", enable) 
        },
        enableVideo({commit}, enable){
            commit("enableVideo", enable) 
        },
        enableFooter({commit}, enable){
            commit("enableFooter", enable) 
        },
        async fetchRegistrationDueDate({commit}){
            const rez = await fetch("https://api.vball.moscow/app/registration_due_date"); 
            const data = await rez.json();
            const dueDate = data.key;
            commit('setDueDate', dueDate);
        },
        async setRegistrationDueDate({commit}, dueDateTimeStamp){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" }
              };
              const postUrl="https://api.vball.moscow/app/registration_due_date/" + dueDateTimeStamp + "/9d81e96d-c32d-4641-bf76-f1844c5f2529";
              const res = await fetch(postUrl, requestOptions); 
              const result = await res.json();
              if(result.status === "OK"){
                commit('setDueDate', dueDateTimeStamp);
              }
              else{
                commit('dueDateError');
              }
              
        }
    },
    mutations : {
        setDueDate(state, dueDate){
            state.registrationDueDate = dueDate;
            state.dueDateStatus = "OK";
        },
        dueDateError(state){
            state.dueDateStatus = "ERROR";
        },
        toggleFormModal(state){
            state.formModal = !state.formModal;
        },
        enableHeader(state, enable){
            state.isHeaderEnabled = enable; 
        },
        enableVideo(state, enable){
            state.isVideoEnabled = enable; 
        },
        enableFooter(state, enable){
            state.IsFooterEnabled = enable; 
        }
    },
    state : {
        registrationDueDate: null,
        dueDateStatus: "TODO",
        formModal : false,
        isHeaderEnabled : true,
        isVideoEnabled: true,
        IsFooterEnabled: true
    },
    getters : {
        registrationDueDate(state){
            return state.registrationDueDate;
        },
        dueDateStatus(state){
            return state.dueDateStatus;
        },
        formModal(state){
            return state.formModal
        },
        isHeaderEnabled(state){
            return state.isHeaderEnabled
        },
        isVideoEnabled(state){
            return state.isVideoEnabled
        },
        isFooterEnabled(state){
            return state.IsFooterEnabled
        }
    }
}