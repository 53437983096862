<template>
  <v-container class="main-container" id="main-container">
    
    <v-container style="height: 100%">
      <v-row no-gutters align="center" justify="space-between" class="main-content">
        <v-col cols="12" md="6" >
          <v-card elevation="0" class="title-card">
            <h1 class="white--text mega-title">Школа пляжного волейбола <span class="yellow--text text--lighten-2">Vball</span></h1>
            <h2 class="white--text secondary-title">Мы учим играть с нуля и <span class="yellow--text text--lighten-2">сразу правильно</span></h2>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" >
          <SendForm />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import SendForm from "@/components/MainPage/SendForm"
export default {
  components:{
    SendForm
  }
}
</script>
<style scoped>
@media screen and (min-width: 300px){
  .mega-title{
    text-shadow: 1px 1px 2px black;
  }
}
@media screen and (min-width: 900px){
  .mega-title{
    font-size: 60px;
    text-shadow: 1px 1px 2px black;
  }
}
.secondary-title{
  text-shadow: 1px 1px 2px black;
}

.title-card{
  background: transparent !important;
}
.main-container{
  background: rgba(0,0,0, .5);
  max-width: 100% !important;
  margin: 0;
  padding: 0;
}

.main-content{
  height: 100%;
}
</style>
