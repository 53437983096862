<template>
    <v-parallax :src="$vuetify.breakpoint.xs ? 'img/pics/paralax_bg_mob.jpg' : 'img/pics/parallax_bg_3.jpg'" height="500">
        <v-container class="pa-0">

        
        <v-card class="pa-6 card-benefits" elevation="15">
            <v-container class="pa-0">
                <h2>Школа волейбола <span class="blue--text text--darken-4">V'Ball</span> - это</h2>
            </v-container>
            <v-carousel 
                class="mt-2"
                hide-delimiters 
                interval="4000" 
                height="240"
                continuous
                cycle
                :show-arrows="false"
                progress
                progress-color="blue darken-4">
                <v-carousel-item>
                    <v-container class="pa-0 mb-2">
                        <h2 class="blue--text text--darken-4">Удобное время</h2>
                        <h4 class="black--text">Мы занимаемся после работы, посмотри расписание - там точно есть удобное время для тебя</h4>
                        <v-btn class="mt-2" color="blue darken-4" @click="schedule">Рассписание</v-btn>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container class="pa-0 mb-2">
                        <h2 class="blue--text text--darken-4">Удобное место</h2>
                        <h4 class="black--text">Мы работаем на площадках, которые расположены по всей Москве</h4>
                        <v-btn class="mt-2" color="blue darken-4" @click="locations">Площадки</v-btn>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container class="pa-0 mb-2">
                        <h2 class="blue--text text--darken-4">Обучение с нуля</h2>
                        <h4 class="black--text">Наши тренеры используют методику обучения и упражнения прямо с треноровок сборной России</h4>
                        <v-btn class="mt-2" color="blue darken-4" @click="coaches">Тренеры</v-btn>
                    </v-container>
                </v-carousel-item>
            </v-carousel>
        </v-card>
        </v-container>
    </v-parallax>
</template>
<script>
import goTo from 'vuetify/es5/services/goto'
export default {
    methods:{
        schedule(){
            goTo(this.getOffset(document.getElementById('schedule')).top - 56);
        },
        locations(){
            goTo(this.getOffset(document.getElementById('locations')).top - 56);
        },
        coaches(){
            goTo(this.getOffset(document.getElementById('coaches')).top - 56);
        },
        getOffset( el ) {
            var _x = 0;
            var _y = 0;
            while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
                _x += el.offsetLeft - el.scrollLeft;
                _y += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
            }
            return { top: _y, left: _x };
        }
    }
}
</script>
<style scoped>
.card-benefits{
    background: rgba(255,255,255,0.8) !important;
}
</style>