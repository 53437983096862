export default{
    actions: {
      auth({commit}, userdata){
        if(userdata.login === "admin@vball.moscow" && userdata.password === "Megapassword123!"){
            commit('auth', userdata)
        }
        else{
            commit('error')
        }
      },
      logout({commit}){
          commit('logout');
      }
    },
    mutations: {
      auth(state, userdata){
        state.adminLoginStatus = "OK"
        state.adminUser = userdata
        localStorage.setItem('currentUser', JSON.stringify(userdata))
      },
      error(state){
          state.adminLoginStatus = "ERROR"
      },
      logout(state){
        state.adminLoginStatus = "TODO";
        state.adminUser = null;
        localStorage.removeItem('currentUser');
      },
      initStore(state){
          const currentUserJson = localStorage.getItem('currentUser')
        if (currentUserJson) {
            state.adminUser = JSON.parse(currentUserJson);
            state.adminLoginStatus = "OK"
        }
      }
    },
    state: {
        adminLoginStatus: "TODO",
        adminUser: null
    },
    getters: {
        adminLoginStatus(state){
        return state.adminLoginStatus
      },
      adminUser(state){
        return state.adminUser
      }
    }
  }
  