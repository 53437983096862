<template>
  <v-app id="app">
    <div class="pa-0 ma-0" id="loading" :v-if="loading"></div>
    <VideoBg v-if="isVideoEnabled"/>
    <v-app-bar 
      v-if="isHeaderEnabled"
      app
      class="app-bar"
      id="app-bar"
      elevate-on-scroll
      >
        <v-container class="app-bar-content">
          <v-toolbar-title><v-btn icon href="/"><v-img width="54" src="@/assets/logo.png"></v-img></v-btn></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small tile class="white--text" @click="locations">Локации</v-btn>
          <v-btn text small tile class="white--text" @click="schedule">Расписание</v-btn>
          <v-btn text small tile class="white--text" @click="price">Цены</v-btn>
        </v-container>
    </v-app-bar>
    <v-main>
        <router-view></router-view>
    </v-main>
    <Footer class="mt-10" v-if="isFooterEnabled"/>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import goTo from 'vuetify/es5/services/goto'
import VideoBg from "@/components/MainPage/VideoBg"
import Footer from "@/components/MainPage/Footer"
export default {
  name: 'App',
  components:{
    VideoBg, Footer
  },
  data: () => ({
    loading: true
  }),
  methods:{
    schedule(){
      goTo(this.getOffset(document.getElementById('schedule')).top - 56);
    },
    price(){
      goTo(this.getOffset(document.getElementById('prices')).top - 56);
    },
    locations(){
      goTo(this.getOffset(document.getElementById('locations')).top - 56);
    },
    getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }
  },
  computed:{...mapGetters(["isHeaderEnabled", 'isVideoEnabled', 'isFooterEnabled'])},
  mounted(){
    var mainContainer = document.getElementById('main-container');
    var appBarHeight = document.getElementById('app-bar') ? document.getElementById('app-bar').offsetHeight : 0;
    var bgVidHeight = document.getElementById('bgvid') ? document.getElementById('bgvid').offsetHeight : 0;
    console.log(bgVidHeight);
    if(mainContainer){
      mainContainer.style.height = (bgVidHeight - appBarHeight) + "px";
      console.log(mainContainer.style.height);
    }
    this.loading = false;
  }
};
</script>
<style>
@import url('./fonts/stylesheet.css');

#app{
    font-family: 'URW Geometric' !important;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    color: #3a4b68;
    background: #fff;
}
.grecaptcha-badge {
	visibility: hidden;
}
.app-bar{
  background: rgba(0,0,0, .5) !important;
}
.app-bar-content{
    align-items: center;
    display: flex;
    position: relative;
    z-index: 0;
}
@media (min-width: 1904px){
  .container {
    max-width: 1185px !important;
  }
}

</style>
