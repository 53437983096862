<template>
    <v-container id="prices" ref="prices">
        <h1>Цены</h1>
        <h2>Разовые тренировки:</h2>
        <v-row class="mt-1">
            <v-col cols="12" sm="6">
                <v-card elevation="15" color="blue darken-4" class="pa-4 white--text">
                    <h1>Первая тренировка</h1>
                    <p>Первая тренировка по стоимости аренды зала. Приходи, попробуй!</p>
                    
                    <v-expansion-panels
                        multiple
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h1>1.400 ₽</h1></v-expansion-panel-header>
                            <!--v-expansion-panel-content>
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Центр
                                            </th>
                                            <th class="text-left">
                                                Стоимость
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in trial"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card elevation="15" color="blue darken-4" class="pa-4 white--text">
                    <h1>Разовая тренировка</h1>
                    <p>Для тех, у кого плавающее расписание. Тренировка с возможностью отмены за 24 часа до тренировки</p>
                    <v-expansion-panels
                        multiple
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h1>1.800 ₽</h1></v-expansion-panel-header>
                            <!--v-expansion-panel-content>
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Центр
                                            </th>
                                            <th class="text-left">
                                                Стоимость
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in first"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
        <h2 class="mt-4">Абонементы:</h2>
        <p>Мы ввели единый прайс на все локации. <br> Теперь Ваш абонемент будет действовать на любой локации!</p>
        <v-row>
            <v-col cols="12" sm="6">
                <v-card elevation="15" color="blue darken-4" class="pa-4 white--text">
                    <h1>Абонемент на 5</h1>
                    <p>от 1700₽ за тренировку</p>
                    <v-expansion-panels
                        multiple
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h1>8.500 ₽</h1></v-expansion-panel-header>
                            <!--v-expansion-panel-content>
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Центр
                                            </th>
                                            <th class="text-left">
                                                Стоимость
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in five"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card elevation="15" color="blue darken-4" class="pa-4 white--text">
                    <h1>Абонемент на 10</h1>
                    <p>от 1600₽ за тренировку</p>
                    <v-expansion-panels
                        multiple
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h1>16.000 ₽</h1></v-expansion-panel-header>
                            <!--v-expansion-panel-content>
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Центр
                                            </th>
                                            <th class="text-left">
                                                Стоимость
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in ten"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card elevation="15" color="blue darken-4" class="pa-4 white--text">
                    <h1>Абонемент на 15</h1>
                    <p>от 1500₽ за тренировку</p>
                    <v-expansion-panels
                        multiple
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h1>22.500 ₽</h1></v-expansion-panel-header>
                            <!--v-expansion-panel-content>
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Центр
                                            </th>
                                            <th class="text-left">
                                                Стоимость
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in fifteen"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card elevation="15" color="blue darken-4" class="pa-4 white--text">
                    <h1>Абонемент на 20</h1>
                    <p>от 1400₽ за тренировку</p>
                    <v-expansion-panels
                        multiple
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header><h1>28.000 ₽</h1></v-expansion-panel-header>
                            <!--v-expansion-panel-content>
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Центр
                                            </th>
                                            <th class="text-left">
                                                Стоимость
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in twenty"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-expansion-panel-content>-->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
               
            </v-col>
        </v-row>
         <small ><br>*Обратите внимание, абонемент имеет ограниченный срок действия*</small>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            trial:[
                {name: "Leto", price: "1400 ₽"},
                {name: "Panda Voley", price: "1400 ₽"},
                {name: "Match Point", price: "1000 ₽"},
                {name: "Global Sport", price: "1400 ₽"}
            ],
            first:[
                {name: "Leto", price: "1800 ₽"},
                {name: "Panda Voley", price: "1800 ₽"},
                {name: "Match Point", price: "1500 ₽"},
                {name: "Global Sport", price: "2000 ₽"}
            ],
            five:[
                {name: "Leto", price: "8 500 ₽"},
                {name: "Panda Voley", price: "8 500 ₽"},
                {name: "Match Point", price: "7 000 ₽"},
                {name: "Global Sport", price: "9 000 ₽"}
            ],
            ten:[
                {name: "Leto", price: "16 000 ₽"},
                {name: "Panda Voley", price: "16 000 ₽"},
                {name: "Match Point", price: "13 000 ₽"},
                {name: "Global Sport", price: "17 000 ₽"}
            ],
            fifteen:[
                {name: "Leto", price: "22 500 ₽"},
                {name: "Panda Voley", price: "22 500 ₽"},
                {name: "Match Point", price: "18 000 ₽"},
                {name: "Global Sport", price: "24 000 ₽"}
            ],
            twenty:[
                {name: "Leto", price: "28 500 ₽"},
                {name: "Panda Voley", price: "28 500 ₽"},
                {name: "Match Point", price: "22 000 ₽"},
                {name: "Global Sport", price: "30 000 ₽"}
            ]
        }
    }
}
</script>