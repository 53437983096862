import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import "./plugins/vuetify-mask.js";
import VueYandexMetrika from 'vue-yandex-metrika' 
import VueAnalytics from 'vue-analytics';
import titleMixin from './mixins/titleMixin'


Vue.config.productionTip = false

Vue.mixin(titleMixin)

Vue.use(VueYandexMetrika, {
  id: 71205898,
  router: router,
  env: process.env.NODE_ENV
})

Vue.use(VueAnalytics, {
  id: '2270011833',
  router
})

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() { this.$store.commit('initStore')},
  render: h => h(App)
}).$mount('#app')
