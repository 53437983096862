<template>
    <div>
        <v-card 
            elevation="15" 
            rounded="md"
            class="pa-4 form-card"
            v-if="status === 'TODO' || status === 'ERROR'"
        >
        <h2 class="blue--text text--darken-4">Открыт набор! <br>Первое занятие {{dueDate}}</h2>
        <v-form ref="form" v-model="valid" class="pt-2" @submit.prevent="send" lazy-validation>
            <v-text-field
            :rules="[rules.required, rules.min, rules.max]"
            color="blue darken-4"
            outlined
            dense
            v-model="name"
            label="Ваше имя"
            />
            <v-text-field-simplemask
            color="blue darken-4"
            v-model="phone"
            :label="label"
            v-bind:properties="{
                prefix: '+7',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: true,
                dense: true,
                clearable: true,
                placeholder: '',
                rules:[value => !!value || 'Это поле обязательно.']
            }"
            v-bind:options="{
                inputMask: '(###) ###-##-##',
                outputMask: '##########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
            }"
            v-bind:focus="focus"
            v-on:focus="focus = false"
            />
            <vue-recaptcha ref="invisibleRecaptcha" sitekey="6LfL-kAaAAAAACoRHJgg9YCfW6lGOakov_gC2x4H" @verify="onVerify" :loadRecaptchaScript="true" size="invisible">
            </vue-recaptcha>
            <div class="d-flex justify-end align-center">
                <h5 class="blue-gray--text">До конца регистрации {{daysUntil}} д.!</h5>
            <v-btn color="blue darken-4 send-button" class="ml-2 white--text" :disabled="!valid" :loading="loading" type="submit">Отправить</v-btn>
            </div>
        <p class="text-right grey--text micro">Защита от спама reCAPTCHA <a class="pa-0 grey--text ma-0 micro" href="https://policies.google.com/privacy?hl=ru" target="_blank">Конфиденциальность</a> и <a class="pa-0 grey--text ma-0 micro" href="https://policies.google.com/terms?hl=ru" target="_blank">Условия использования</a></p>
        </v-form>
        
        </v-card>
        <v-card v-else elevation="15" 
            rounded="md"
            class="pa-4 form-card">
            <h2>Спасибо за обращение! Как только у меня закончатся тренировки - я вам позвоню!</h2>
            <v-btn color="blue darken-4" class="mt-2 white--text" @click="reset()">Отправить еще раз</v-btn>
        </v-card>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import VueRecaptcha from 'vue-recaptcha';
  export default {
    name: 'Main',
    data () {
      return {
        phone: "",
        name: "",
        focus: false,
        valid: false,
        label: "Номер телефона",
        loading: false,
        rules: {
          required: value => !!value || 'Это поле обязательно.',
          min: v => v.length >= 2 || 'Минимальная длина 5 символов',
          max: v => v.length <= 15 || 'Максимальная длина 15 символов',
        },
      }
    },
    components: { VueRecaptcha },
    computed: {
        ...mapGetters(['status', 'registrationDueDate', 'dueDateStatus']),
        dueDate(){
            if (!this.registrationDueDate) return null
            const dateTime = new Date(this.registrationDueDate * 1000);
            const [month, day] = [dateTime.getMonth() - 1, dateTime.getDate()]
            const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
                "июля", "августа", "сентября", "октября", "ноября", "декабря"
                ];
            return `${day} ${monthNames[month]}`
        },
        daysUntil(){
            if (!this.registrationDueDate) return null
            const dateTime = new Date(this.registrationDueDate * 1000);
            const [year, month, day] = [dateTime.getFullYear(), dateTime.getMonth() - 1, dateTime.getDate()]
            const realDateTime = new Date(year, month, day);
            const today = new Date();
            const diffTime = Math.abs(realDateTime - today);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        }
    },
    async mounted(){
        if(this.dueDateStatus !== "OK"){
            this.loading = true;
            await this.fetchRegistrationDueDate();
            this.loading = false;
        }
    },
    methods:{
      ...mapActions(['sendUser', 'fetchRegistrationDueDate', 'resetStatus']),
      async onVerify(response){
          console.log('verify' + response);
          this.loading = true;
            console.log(this.name)
            await this.sendUser({name: this.name, phone: this.phone});
            console.log(this.phone)
            this.loading = false;
      },
      async send(){
          this.valid = this.$refs.form.validate()
          console.log(this.valid)
            if(this.valid){
                this.loading = true;
                await this.sendUser({name: this.name, phone: this.phone});
                this.loading = false
                return
            }
        
      },
      reset(){
          this.phone = "";
          this.name = "";
          this.resetStatus()
      }
    }
  }
</script>
<style scoped>
.form-card{
  background: rgba(255,255,255, .9) !important;
}
.micro{
    font-size: 9px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: #0d47a1!important;
    border-color: #0d47a1!important;
    color: #fff!important;
}
</style>