<template >
    <v-container >
        <h1>Расписание</h1>
        <v-card elevation="15" class="mt-4 pb-2">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr class="table-head">
                        <th class="text-left table-head-cell">
                            <h2>День</h2>
                        </th>
                        <!--<th class="text-left table-head-cell">
                            <h2>Leto</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Panda Volley</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Match Point</h2>
                        </th>-->
                        <th class="text-left table-head-cell">
                            <h2>Парк Горького</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Воробьевы горы</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Global Sport</h2>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in schedule"
                        :key="item.name"
                        >
                        <td>{{ item.name }}</td>
                        <!--<td>{{ item.leto }}</td>
                        <td>{{ item.panda }}</td>
                        <td>{{ item.match }}</td>-->
                        <td>{{ item.park }}</td>
                        <td>{{ item.vor }}</td>
                        <td>{{ item.global }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            schedule:[
                {name: "Понедельник", leto: "", panda: "", match: "", global: "", park: "", vor: "9:45, 21:00"},
                {name: "Вторник", leto: "18:45, 20:15", panda: "12:45, 21:00", match: "7:45", global: "", park: "20:00", vor: ""},
                {name: "Среда", leto: "20:30", panda: "13:00", match: "21:00", global: "", park: "20:00", vor: "9:45, 21:00"},
                {name: "Четверг", leto: "", panda: "21:00", match: "19:00", global: "21:00", park: "20:00", vor: ""},
                {name: "Пятница", leto: "19:00", panda: "", match: "", global: "", park: "", vor: ""},
                {name: "Суббота", leto: "", panda: "", match: "", global: "", park: "", vor: ""},
                {name: "Воскресенье", leto: "17:30, 19:10", panda: "", match: "", global: "", park: "16:00, 18:00", vor: ""},
            ]
        }
    }
}
</script>
<style scoped>
.table-head{
    background: #0d47a0;
}
.table-head-cell{
    color: white !important;
}
</style>